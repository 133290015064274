import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';

interface Props {
  children: JSX.Element | JSX.Element[];
  // ogImage: string; // TODO - Enable when images are ready
  // ogImageAlt: string;  // TODO - Enable when images are ready

  // Max 70 chars
  // Max 200 Chars
  // twitterImage: string; // TODO - Enable when images are ready
  // twitterImageAlt: string; // TODO - Enable when images are ready

}

export default function HeaderLayout(props: Props) {
  const site = 'https://tali.ai';
  const canonicalURL = site + useRouter().asPath;
  
  return (
    <>
      <Script id="gtag" strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />

      <Script id="google-data-layer" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
        `}
      </Script>
      <Head>
        <meta charSet="utf-8" key="charset" />
        <meta name="author" content="Tali AI" key="author" />
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
        <link rel="canonical" href={canonicalURL} key="canonical" />

        {/* OG Tags */}
        <meta property="og:url" content={canonicalURL} key="og:url"/>
        <meta property="og:site_name" content="Tali AI" key="og:site_name"/>
        <meta property="og:locale" content="en_US" key="og:locale"/>
        {/* TODO: OG Set Image url */}
        {/* <meta property="og:image" content={props.ogImage} key="og:image" /> */}
        {/* <meta property="og:image:alt" content={props.ogImageAlt} key="og:image:alt" /> */}

        {/* Twitter Tags */}
        <meta name="twitter:site" content="@TaliAICompany" key="twitter:site" />
        {/* TODO: Twitter Set Image url */}
        {/* <meta property="twitter:image" content={props.twitterImage} key="twitter:image" /> */}
        {/* <meta property="twttier:image:alt" content={props.twitterImageAlt} key="twttier:image:alt" />  */}

        {/* Fav Icons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.webp" />
        <link rel="icon" type="image/webp" sizes="32x32" href="/favicon-32x32.webp" />
        <link rel="icon" type="image/webp" sizes="16x16" href="/favicon-16x16.webp" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>
      {props.children}
    </>
  );
}
