import { ReactNode, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { ddSetUser, initializeDatadog } from '../datadog/rum';

/**
 * Used for communication between the website and the chrome extension.
 * Must match the values in the chrome extension
 */
enum TaliWebsiteMessage {
  login = 'sync-login',
  logout = 'sync-logout',
}

let lastLoginCheck = new Date(0).getTime();
let lastLogoutCheck = new Date(0).getTime();

const AuthenticationManager = (props: { children: ReactNode }) => {
  const router = useRouter();
  const { isLoading, error, user, checkSession } = useUser();

  useEffect(() => {
    initializeDatadog();
  }, []);

  useEffect(() => {
    if (!isLoading && !error) {
      if (user) {
        // @ts-expect-error - UserProfile typings
        const userId = user['https://tali.ai/app_metadata']['userId'] || 'unknown';
        ddSetUser(userId, 'registered');
        // @ts-expect-error - UserProfile typings
        localStorage.setItem('profileName', user['https://tali.ai/app_metadata']['firstName'] || '');
        Sentry.setUser({ id: userId, email: user.email || '' });
      } else {
        ddSetUser('', 'anonymous');
        localStorage.removeItem('profileName');
        Sentry.setUser(null);
      }
    }
  }, [error, isLoading, user]);

  // Check if the user is authenticated (SSO) on page load
  useEffect(() => {
    const checkExistingSession = async () => {
      const href = window.location.href;
      if (!href.includes('/profile?')) { // Don't modify the login during the signup flow
        await fetch('/api/auth/login?silent=true', { mode: 'no-cors' } );
        checkSession();
      }
    };

    checkExistingSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add a listener to the page to reload if the user logs in via the extension
  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      // We only accept messages from ourselves
      if (event.source != window) {
        return;
      }
      switch (event.data.type) {
        case TaliWebsiteMessage.login: {
          const href = window.location.href;
          if (!href.includes('/profile?') && typeof window !== 'undefined') { // Don't modify the login during the signup flow
            const now = new Date().getTime();
            if (lastLoginCheck + 1000 <= now) {
              lastLoginCheck = now;
              await fetch('/api/auth/login?silent=true', { mode: 'no-cors' } );
              checkSession();
            }
          }

          break;
        }
        case TaliWebsiteMessage.logout: {
          const now = new Date().getTime();
          if (lastLogoutCheck + 1000 <= now) {
            lastLogoutCheck = now;
            await fetch('/api/auth/logout', { mode: 'no-cors' } );
            checkSession();
          }
          break;
        }
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [checkSession, router]);

  return <>{props.children}</>;
};

export default AuthenticationManager;