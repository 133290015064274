import axios from 'axios';
import { createContext, useState, useEffect, useCallback } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import makeUrl from '~/services/utils/makeUrl';

interface UserInfoApiResponse {
  first_name: string;
  last_name: string;
  email: string;
  scribe_usage_ms: number;
  plan: Plan;
  expiry: number;
  customer_id: string;
  claims: string[];
  tos_accepted: boolean;
  medical_specialty: string;
  organization: string | null;
  trial: boolean;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  claims: string[];
  expiry: number;
  scribeUsageMs: number;
  medicalSpecialty: string;
  customerId: string;
}

interface AuthContext {
  userId: string;
  user: User | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  isPremium: boolean;
  isPro: boolean;
  trial: boolean;
  organization: string | null;
  isEnterprise: boolean;
  plan: Plan;
  refreshUser: () => void;
}

export enum Plan {
  loading = 'loading',
  free = 'free',
  premium = 'premium',
  pro = 'pro',
};

export const AuthContext = createContext<AuthContext>({
  userId: '',
  isLoading: true,
  user: undefined,
  isAuthenticated: false,
  isPremium: false,
  isPro: false,
  trial: false,
  organization: null,
  isEnterprise: false,
  plan: Plan.loading,
  refreshUser: () => null,
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userId, setUserId] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<User | undefined>();
  const [fetchingUserInfo, setFetchingUserInfo] = useState(false);
  const { user, isLoading } = useUser();
  const [isPremium, setIsPremium] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [trial, setTrial] = useState(false);
  const [organization, setOrganization] = useState<string | null>(null);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [plan, setPlan] = useState(Plan.loading);
  const [forceRefetch, setForceRefetch] = useState(0);

  useEffect(() => {
    if (!isLoading && user) {
      setIsAuthenticated(true);
      const metadata = user?.['https://tali.ai/app_metadata'] as Record<
        string,
        string | Plan[] | boolean
      >;
      setUserId(metadata.userId as string);
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (() => {
      setFetchingUserInfo(true);
      const url = makeUrl('/user/{USER_ID}', { USER_ID: userId });
      const config = {
        headers: {
          next_target: url,
          next_method: 'get',
        },
      };
      axios.post('/api/fetch', {}, config).then(({ data }) => {
        const {
          first_name,
          last_name,
          email,
          claims,
          plan,
          scribe_usage_ms,
          expiry,
          medical_specialty,
          trial,
          organization,
          customer_id,
        } = data.data as UserInfoApiResponse || {};

        setUserInfo({
          firstName: first_name,
          lastName: last_name,
          email,
          userId,
          claims,
          scribeUsageMs: scribe_usage_ms,
          expiry,
          medicalSpecialty: medical_specialty,
          customerId: customer_id,
        });

        // Set whether the user is on a trial of pro plan
        setTrial(trial);

        // Set the user's organization and mark them as an enterprise user
        if (organization) {
          setOrganization(organization);
          setIsEnterprise(true);
        }

        // Set the user's plan
        if (plan === Plan.pro) {
          setIsPro(true);
          setPlan(Plan.pro);
        } else if (plan === Plan.premium) {
          setIsPremium(true);
          setPlan(Plan.premium);
        } else {
          setPlan(Plan.free);
        }

        setFetchingUserInfo(false);
      });
    })();
  }, [isAuthenticated, userId, forceRefetch]);

  /**
   * Fetches the latest information from the server about the user
   */
  const refreshUser = useCallback(() => {
    setForceRefetch(val => val + 1);
  }, [setForceRefetch]);

  /**
   * Forces a refresh of the user's premium status.
   */
  return (
    <AuthContext.Provider
      value={{
        userId,
        isAuthenticated,
        isLoading: isLoading || fetchingUserInfo,
        user: userInfo,
        isPremium,
        isPro,
        trial,
        organization,
        isEnterprise,
        plan,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
