import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { StyledEngineProvider } from '@mui/material';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { initializeDatadog } from '../services/datadog/rum';
import Header from '../layouts/Header';
import AuthenticationManager from '../services/authentication/AuthenticationManager';
import ToastParent from '../components/toast';
import { AuthContextProvider } from '~/contexts/authentication';


function App({ Component, pageProps }: AppProps) {
  initializeDatadog();

  return (
    <UserProvider>
      <AuthContextProvider>
        <AuthenticationManager>
          <StyledEngineProvider injectFirst>
            <Header>
              <Component {...pageProps} />
              <ToastParent />
            </Header>
          </StyledEngineProvider>
        </AuthenticationManager>
      </AuthContextProvider>
    </UserProvider>
  );
}

export default App;
