const BASE_URL = process.env.NEXT_PUBLIC_SERVER_URL;
const API_KEY = process.env.NEXT_PUBLIC_API_KEY || '';

const defaultAPIVersion = 'v1';
enum ApiVersion {
  'v1' = '/api/v1',
}

export default function makeUrl(
  urlWithPlaceholders: string,
  substitutions: { [key: string]: string },
  options: {
    apiVersion?: ApiVersion;
    isASR?: boolean;
    isNLP?: boolean;
    isAnalytics?: boolean;
  } = {},
) {
  const { apiVersion, isASR, isNLP, isAnalytics } = options;
  let url: string = urlWithPlaceholders.replace(
    /{(\w+)}/g,
    (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
      substitutions.hasOwnProperty(placeholderWithoutDelimiters)
        ? substitutions[placeholderWithoutDelimiters]
        : placeholderWithDelimiters,
  );

  if (apiVersion) {
    url = `${apiVersion}` + url;
  } else {
    url = `${ApiVersion[defaultAPIVersion]}` + url;
  }

  if (isASR) {
    url = `${BASE_URL}/asr` + url;
  } else if (isNLP) {
    url = `${BASE_URL}/nlp` + url;
  } else if (isAnalytics) {
    url = `${BASE_URL}/reporting` + url;
  } else {
    url = `${BASE_URL}/api` + url;
  }

  return url;
}

export { API_KEY };