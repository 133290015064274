
import Image from 'next/image';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Text from '../display/Text';

import CloseIconSuccess from '../../public/toast/close_toast_success.svg';
import CloseIconWarning from '../../public/toast/close_toast_warning.svg';
import CloseIconError from '../../public/toast/close_toast_error.svg';
import CloseIconInfo from '../../public/toast/close_toast_info.svg';
interface CloseToast {
  closeToast: () => void;
  toastType?: 'success' | 'warning' | 'error' | 'info';
}

const CloseButton = ({ closeToast, toastType: type }: CloseToast) => {
  let icon = CloseIconSuccess;
  const style = clsx(styles.toastBtn, {
    [styles.toastBtnSuccess]: !type || type === 'success',
    [styles.toastBtnWarning]: type === 'warning',
    [styles.toastBtnError]: type === 'error',
    [styles.toastBtnInfo]: type === 'info',
  });

  if (type === 'warning') {
    icon = CloseIconWarning;
  } else if (type === 'error') {
    icon = CloseIconError;
  } else if (type === 'info') {
    icon = CloseIconInfo;
  }

  return (<div className={styles.toastBtnContainer}>
    <button className={style} onClick={closeToast}>
      <Image
        src={icon}
        width={16}
        height={16}
        layout="fixed"
        className={clsx()}
      />
    </button>
  </div>);
};

import styles from './toast.module.scss';
import clsx from 'clsx';

const ToastParent = () => {
  return (<>
    <ToastContainer
      position="bottom-left"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      pauseOnFocusLoss={true}
      draggable={true}
      pauseOnHover
      limit={3}
      icon={false}

      toastClassName={styles.toast}
      progressClassName={styles.progressBarSuccess}
      closeButton={CloseButton}
    />
    <div id="toastGradient" className={styles.shader}></div>
  </>);
};

const ToastMsg = ({ title, description }: { title: string, description: string }) => {
  return <div className={styles.toastMessage}>
    <Text size="p1" type="light" title={title} textStyle={styles.toastTitle} textAlign="start"/>
    <Text size="p2" type="light" title={description} textAlign="start"/>
  </div>;
};

function openToast(): void {
  const gradient = document.getElementById('toastGradient');
  if (gradient) {
    gradient.style.display = 'block';
  }
}

function closeToast(): void {
  const gradient = document.getElementById('toastGradient');
  if (gradient) {
    gradient.style.display = 'none';
  }
}

export function displayToast(title: string, description: string, options?: ToastOptions) {
  return toast(<ToastMsg title={title} description={description} />,  {
    ...options,
    onOpen: openToast,
    onClose: closeToast
  });
}

export function displayToastError(title: string, description: string, options?: ToastOptions) {
  return toast(<ToastMsg
    title={title}
    description={description}
  />,
  {
    className: clsx(styles.toast, styles.toastError),
    progressClassName: styles.progressBarError,
    closeButton: (props) => <CloseButton {...props} toastType="error" />,
    ...options,
    onOpen: openToast,
    onClose: closeToast,
  });
}

export function displayToastWarning(title: string, description: string, toastId: string, options?: ToastOptions) {
  return toast(<ToastMsg
    title={title}
    description={description}
  />,
  {
    className: clsx(styles.toast, styles.toastWarning),
    progressClassName: styles.progressBarWarning,
    closeButton: (props) => <CloseButton {...props} toastType="warning" />,
    toastId,
    ...options,
    onOpen: openToast,
    onClose: closeToast,
  });
}

export function displayToastInfo(title: string, description: string, options?: ToastOptions) {
  return toast(<ToastMsg
    title={title}
    description={description}
  />,
  {
    className: clsx(styles.toast, styles.toastInfo),
    progressClassName: styles.progressBarInfo,
    closeButton: (props) => <CloseButton {...props} toastType="info" />,
    ...options,
    onOpen: openToast,
    onClose: closeToast,
  });
}

export default ToastParent;